import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  CloseButton,
  Spinner,
} from "react-bootstrap";
import CustomImage from "../components/CustomImage";
import Loading from "../components/Loading";
import "./Survey.css";
import { authenticatedFetch } from "../helpFunctions/authFetch";
import { getEnvVariable } from "../helpFunctions/getEnvVariable";

const Survey = () => {
  const emptyState = {
    files: [],
    itemType: "",
    mediaName: "",
    caption: "",
    variantsID: [],
    selection: "",
  };
  const [prevState, setPrevState] = useState(emptyState);
  const [currentState, setCurrentState] = useState(emptyState);
  const [nextState, setNextState] = useState(emptyState);

  // Add a queue for prefetched items
  const [prefetchQueue, setPrefetchQueue] = useState([]);
  const [isPrefetching, setIsPrefetching] = useState(false);
  const prefetchSize = 3; // Number of items to keep in the prefetch queue

  // Add state to track initial loading
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const [alertMessage, setAlertMessage] = useState({});
  const [alertAnimation, setAlertAnimation] = useState(false);
  const [surveyTitle, setSurveyTitle] = useState("");
  const voteDetails = useRef({});
  const [isSend, setIsSend] = useState(true);
  const [isFetchingNext, setIsFetchingNext] = useState(false);
  const surveyId = useParams().surveyId; // Extract survey ID from URL
  const apiBaseUrl = getEnvVariable("API_BASE_URL");

  const navigate = useNavigate();

  const videoRefs = useRef([]); // Store refs to video elements

  // Add new state for keyboard selection visual feedback
  const [keyboardSelected, setKeyboardSelected] = useState(null);

  const fetchSurveyTitle = async () => {
    try {
      const response = await authenticatedFetch(
        navigate,
        `${apiBaseUrl}SurveyTitle/${surveyId}`
      );
      const resJson = await response.json();
      if (response.ok) {
        setSurveyTitle(resJson.survey_title || "Which is better?");
      } else {
        throw new Error(resJson.message);
      }
    } catch (error) {
      console.error("Error fetching survey title:", error);
      setSurveyTitle("Which is better?");
    }
  };

  // Modified to fetch a single pair and return it
  const fetchPair = async () => {
    try {
      const response = await authenticatedFetch(
        navigate,
        `${apiBaseUrl}survey/${surveyId}`
      );
      const resJson = await response.json();
      if (!response.ok) {
        throw resJson;
      }

      return {
        files: resJson.files,
        itemType: resJson.file_type,
        mediaName: resJson.media_name,
        caption: resJson.caption || "",
        variantsID: resJson.variants_id,
        selection: "",
      };
    } catch (error) {
      if (
        error.detail ===
        "ValueError('The survey must contain at least two active variants')"
      ) {
        setAlertMessage({
          type: "warning",
          header: "Survey Unavailable",
          msg: "This survey requires at least two active variants to proceed.",
        });
      } else {
        setAlertMessage({
          type: "danger",
          header: "Error",
          msg: `There has been a problem: ${error.detail}`,
        });
      }
      return null;
    }
  };

  // Function to fill the prefetch queue
  const fillPrefetchQueue = async () => {
    if (isPrefetching) return;

    setIsPrefetching(true);

    try {
      // Calculate how many items we need to fetch
      const itemsToFetch = prefetchSize - prefetchQueue.length;

      if (itemsToFetch <= 0) {
        setIsPrefetching(false);
        return;
      }

      // Fetch multiple items in parallel
      const fetchPromises = Array(itemsToFetch).fill().map(() => fetchPair());

      // Update progress as each fetch completes
      if (isInitialLoading) {
        let completedFetches = 0;
        const progressPromises = fetchPromises.map(promise =>
          promise.then(result => {
            completedFetches++;
            setLoadingProgress(Math.floor((completedFetches / itemsToFetch) * 100));
            return result;
          })
        );
        const results = await Promise.all(progressPromises);
        const validResults = results.filter(result => result !== null);
        setPrefetchQueue(prevQueue => [...prevQueue, ...validResults]);
      } else {
        const results = await Promise.all(fetchPromises);
        const validResults = results.filter(result => result !== null);
        setPrefetchQueue(prevQueue => [...prevQueue, ...validResults]);
      }
    } catch (error) {
      console.error("Error filling prefetch queue:", error);
    } finally {
      setIsPrefetching(false);
    }
  };

  // Initial setup - fetch title and preload queue
  useEffect(() => {
    fetchSurveyTitle();

    // Initial preloading
    const preloadQueue = async () => {
      setIsInitialLoading(true);
      setLoadingProgress(0);

      await fillPrefetchQueue();

      // Once we have items in the queue, set up the current state
      if (prefetchQueue.length > 0) {
        const firstItem = prefetchQueue[0];
        setPrefetchQueue(prevQueue => prevQueue.slice(1));
        setCurrentState(firstItem);
      } else {
        // If queue is still empty, try direct fetch as fallback
        const firstPair = await fetchPair();
        if (firstPair) {
          setCurrentState(firstPair);
        }
      }

      setIsInitialLoading(false);
    };

    preloadQueue();
  }, []);

  // Keep the prefetch queue filled
  useEffect(() => {
    if (prefetchQueue.length < prefetchSize && !isPrefetching && !isInitialLoading) {
      fillPrefetchQueue();
    }

    // If we've filled the queue during initial loading, we can start the survey
    if (isInitialLoading && prefetchQueue.length >= prefetchSize - 1) {
      const firstItem = prefetchQueue[0];
      setPrefetchQueue(prevQueue => prevQueue.slice(1));
      setCurrentState(firstItem);
      setIsInitialLoading(false);
    }
  }, [prefetchQueue, isPrefetching, isInitialLoading]);

  // Get the next item from the queue
  const getNextFromQueue = () => {
    if (prefetchQueue.length === 0) {
      return null;
    }

    const nextItem = prefetchQueue[0];
    setPrefetchQueue(prevQueue => prevQueue.slice(1));

    return nextItem;
  };

  const syncVideos = (masterIndex) => {
    const masterVideo = videoRefs.current[masterIndex];

    videoRefs.current.forEach((video, index) => {
      if (index !== masterIndex && video) {
        // Sync time only if the difference is significant (> 0.1s)
        if (Math.abs(video.currentTime - masterVideo.currentTime) > 0.1) {
          video.currentTime = masterVideo.currentTime;
        }
        // Sync play state without interrupting playback
        if (!masterVideo.paused && video.paused) {
          video.play();
        } else if (masterVideo.paused && !video.paused) {
          video.pause();
        }
      }
    });
  };

  // Function to toggle play/pause for all videos
  const togglePlayPause = () => {
    if (!videoRefs.current.length) return;

    const firstVideo = videoRefs.current[0];
    if (firstVideo.paused) {
      videoRefs.current.forEach((video) => video.play());
    } else {
      videoRefs.current.forEach((video) => video.pause());
    }
  };

  // Add a new function to handle arrow key presses
  const handleKeyDown = (e) => {
    // Only process if we have variants to select and we're not currently fetching
    if (currentState.files.length < 2 || !isSend || isFetchingNext) {
      return;
    }

    if (e.code === "ArrowLeft") {
      e.preventDefault(); // Prevent scrolling
      setKeyboardSelected(0); // Set visual feedback
      setCurrentState({ ...currentState, selection: 0 });
      if (isSend) handleNext(0);
    } else if (e.code === "ArrowRight") {
      e.preventDefault(); // Prevent scrolling
      setKeyboardSelected(1); // Set visual feedback
      setCurrentState({ ...currentState, selection: 1 });
      if (isSend) handleNext(1);
    } else if (e.code === "Space") {
      e.preventDefault(); // Prevent scrolling
      togglePlayPause();
    }
  };

  // Add effect to clear the keyboard selection visual feedback after a short delay
  useEffect(() => {
    if (keyboardSelected !== null) {
      const timer = setTimeout(() => {
        setKeyboardSelected(null);
      }, 500); // Clear after 500ms

      return () => clearTimeout(timer);
    }
  }, [keyboardSelected]);

  // Update the existing keydown event listener to include arrow keys
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentState, isSend, isFetchingNext]); // Add dependencies to ensure the handler has access to latest state

  const sendVote = async (selectionIndex) => {
    const getScreenResolution = () => {
      // Get screen width and height using window object
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      return `${width}x${height}`;
    };
    const method = voteDetails.current.undo ? "PUT" : "POST";
    let url = `${apiBaseUrl}vote`;
    if (voteDetails.current.undo) {
      url += `Update/${voteDetails.current.id}`;
      voteDetails.current = { ...voteDetails.current, undo: undefined };
    }

    setIsSend(true);
    const response = await authenticatedFetch(navigate, url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        survey_id: surveyId,
        variant_id_winner: currentState.variantsID[selectionIndex],
        variant_id_loser: currentState.variantsID[1 - selectionIndex],
        media: currentState.mediaName,
        device: /Mobile/.test(navigator.userAgent) ? "Mobile" : "Desktop",
        screen_resolution: getScreenResolution(),
      }),
    });
    if (response.ok) {
      const resJson = await response.json();
      voteDetails.current = { ...voteDetails.current, id: resJson.vote_id };
    } else {
      const resJson = await response.json();
      setAlertMessage({
        type: "danger",
        header: "Error Recording Vote",
        msg: resJson.message || "Failed to record your vote.",
      });
    }
  };

  // Modified handler for "Next" button click
  const handleNext = (selectionIndex) => {
    // Save the current state as previous
    setPrevState({ ...currentState, selection: selectionIndex });

    // If a selection was made, send the vote
    if (isSend && selectionIndex !== "" && selectionIndex !== undefined) {
      setIsSend(false);
      sendVote(selectionIndex);
    }

    // Get the next item from the queue
    const nextItem = getNextFromQueue();

    if (nextItem) {
      // If we have a prefetched item, use it immediately
      setCurrentState(nextItem);
      setIsSend(true);
    } else {
      // If the queue is empty, fetch directly (fallback)
      setIsFetchingNext(true);
      fetchPair().then(newState => {
        if (newState) {
          setCurrentState(newState);
          setIsSend(true);
        }
        setIsFetchingNext(false);
      });
    }

    // Ensure the prefetch queue stays filled
    fillPrefetchQueue();
  };

  const handleUndo = () => {
    // Push current state back to the front of the queue
    setPrefetchQueue(prevQueue => [currentState, ...prevQueue]);

    // Restore the previous state
    setCurrentState(prevState);
    setPrevState(emptyState);
    voteDetails.current = { ...voteDetails.current, undo: true };
  };

  // Custom loading screen with progress indicator
  const renderLoadingScreen = () => (
    <div className="text-center my-5">
      <h2>Preparing Survey</h2>
      <div className="my-4">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
      <div className="progress mb-3" style={{ height: "20px", maxWidth: "400px", margin: "0 auto" }}>
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          style={{ width: `${loadingProgress}%` }}
          aria-valuenow={loadingProgress}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {loadingProgress}%
        </div>
      </div>
      <p className="text-muted">Loading survey items for a smooth experience...</p>
    </div>
  );

  return (
    <div className="text-center justify-content-center">
      {/* Show loading screen during initial loading */}
      {isInitialLoading ? (
        renderLoadingScreen()
      ) : !currentState.files.length && !alertMessage.msg ? (
        <Loading />
      ) : (
        <div>
          <Row className="h1 mb-2">
            <header>{surveyTitle}</header>
          </Row>

          <Container className="mb-5 justify-content-center d-flex flex-column flex-md-row">
            {currentState.files &&
              currentState.files.map((file, index) => {
                const viewIndex = index + 1;
                const isSelected = currentState.selection === index && isSend;
                const borderConfig = "border border-primary border-5";
                return (
                  <Container
                    key={index}
                    className={`me-2 ${isSend ? "pointer-cursor" : ""} ${keyboardSelected === index ? 'keyboard-selected' : ''}`}
                    style={isFetchingNext ? {pointerEvents: "none"} : { opacity: 1, pointerEvents: "auto" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentState({ ...currentState, selection: index });
                      if (isSend) handleNext(index);
                    }}
                  >
                    {/* Render image or video based on item type */}
                    {currentState.itemType === "image" && (
                      <CustomImage
                        className={isSelected ? borderConfig : ""}
                        src={currentState.files[index]}
                        alt={`Item ${viewIndex}`}
                        fluid
                        rounded={2}
                        preload={"auto"}
                      />
                    )}
                    {currentState.itemType === "video" && (
                      <video
                        ref={(el) => (videoRefs.current[index] = el)} // Attach video ref
                        controls
                        className={isSelected ? borderConfig : ""}
                        preload="auto"
                        autoPlay
                        loop
                        muted
                        src={currentState.files[index]}
                        type="video/mp4"
                        alt={`Item ${viewIndex}`}
                        onPlay={() => syncVideos(index)}
                        onPause={() => syncVideos(index)}
                        onTimeUpdate={() => syncVideos(index)}
                      >
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <p className="cursor-pointer">Item {viewIndex}</p>
                  </Container>
                );
              })}
          </Container>

          {/* Rest of your existing UI */}
          <Row lg={3} xl={4} className="mb-5 justify-content-center">
            {/* Undo button */}
            <Col className="text-start">
              <Button
                variant="secondary"
                onClick={() => handleUndo()}
                disabled={prevState.itemType === ""}
              >
                Undo
              </Button>
            </Col>
            {/* Next or Skip button based on selection */}
            <Col className="text-end">
              <Button
                variant={"secondary"}
                onClick={isSend ? () => handleNext(undefined) : null}
                disabled={
                  !isSend || alertMessage.header === "Survey Unavailable" || isFetchingNext
                }
              >
                {isSend && !isFetchingNext ? "Skip" : "Loading..."}
              </Button>
            </Col>
          </Row>

          {/* Caption Text Box */}
          {currentState.caption?.trim() && (
            <Row className="my-4">
              <textarea
                className="form-control text-center mx-auto"
                style={{ maxWidth: "80%" }}
                rows="4"
                value={currentState.caption}
                readOnly
              ></textarea>
            </Row>
          )}

          {/* Add keyboard instructions at the bottom */}
          <div className="instructions mb-3 mt-4">
            Use mouse or keyboard arrows to vote: <span className="key">←</span> for left, <span className="key">→</span> for right
            {currentState.itemType === "video" && <>, <span className="key">Space</span> to play/pause</>}
            <br />
            Please wait for the next pair to load before voting again
          </div>
        </div>
      )}

      {/* Alert message */}
      <Row sm={3} className="ms-2 position-fixed top-0 start-0 z-1000">
        {alertMessage.msg && (
          <Alert
            variant={alertMessage.type}
            className={`${alertMessage.msg && "alert-animate-enter"} ${
              alertAnimation && "alert-animate-exit"
            }`}
          >
            {alertMessage.header !== "Survey Unavailable" && (
              <div className="d-flex justify-content-end">
                <CloseButton
                  onClick={() => {
                    setAlertAnimation(true);
                    setTimeout(() => setAlertMessage(""), 300);
                    setAlertAnimation(false);
                  }}
                />
              </div>
            )}
            <Alert.Heading className="text-start">
              {alertMessage.header}
            </Alert.Heading>
            <p className="text-start">{alertMessage.msg}</p>
          </Alert>
        )}
      </Row>

      {/* Loading overlay */}
      {!isSend && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
            pointerEvents: "none",
          }}
        >
          <Loading /> {/* Optional loading spinner */}
        </div>
      )}
    </div>
  );
};

export default Survey;
