import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getEnvVariable } from '../helpFunctions/getEnvVariable';

function LogIn() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Check if user is already logged in
        const token = localStorage.getItem('authToken');
        if (token) {
            handleRedirectAfterLogin();
        }

        // Reset the redirect flag when the login page loads
        window.isRedirectingToLogin = false;
    }, []);

    const handleRedirectAfterLogin = () => {
        // Get the stored redirect path
        const redirectPath = localStorage.getItem('redirectAfterLogin') || '/manage';
        localStorage.removeItem('redirectAfterLogin');

        // Navigate to the stored path
        console.log("Redirecting to:", redirectPath);
        navigate(redirectPath, { replace: true });
    };

    const onSuccess = (credential) => {
        console.log('Login successful:', credential);
        setIsLoading(true);
        setError(null);

        fetch(`${getEnvVariable('API_BASE_URL')}auth/google`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ credential: credential }),
          })
          .then(response => {
              if (!response.ok) {
                  throw new Error('Authentication failed');
              }
              return response.json();
          })
          .then(data => {
              console.log('Auth response:', data);
              // Store the token in localStorage
              localStorage.setItem('authToken', data.token);

              // Clear the redirect flag
              window.isRedirectingToLogin = false;

              // Use setTimeout to ensure the token is stored before redirecting
              setTimeout(() => {
                  handleRedirectAfterLogin();
              }, 500);
          })
          .catch((error) => {
              console.error('Error:', error);
              setError('Authentication failed. Please try again.');
              setIsLoading(false);
          });
    };

    const onError = (error) => {
        console.error('Login failed:', error);
        setError('Google login failed. Please try again.');
        setIsLoading(false);
    };

    return (
        <div className="container d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
            <div className="card p-4 shadow">
                <h2 className="text-center mb-4">Welcome</h2>
                {isLoading ? (
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="mt-2">Login successful. Please wait while we redirect you...</p>
                    </div>
                ) : (
                    <div>
                        <p className="text-center mb-4">Please sign in to continue</p>
                        {error && (
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        )}
                        <div className="d-flex justify-content-center">
                            <GoogleLogin
                                buttonText="Sign in with Google"
                                onSuccess={credentialResponse => {
                                    console.log(credentialResponse);
                                    onSuccess(credentialResponse.credential);
                                }}
                                onError={onError}
                                cookiePolicy="single_host_origin"
                                useOneTap={false}
                                auto_select={false}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default LogIn;
