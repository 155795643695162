import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Table, Form, Nav, Tab } from 'react-bootstrap';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import objToQueryString from '../helpFunctions/objToQueryParameters';
import getUnique from '../helpFunctions/getUnique'
import Loading from '../components/Loading';
import queryString from 'query-string';
import { authenticatedFetch } from '../helpFunctions/authFetch';
import { getEnvVariable } from '../helpFunctions/getEnvVariable';

const Results = () => {
    const [votes, setVotes] = useState([]);
    const [uniqueCategories, setUniqueCategories] = useState({});
    const [weightedResults, setWeightedResults] = useState(null);
    const [isLoadingWeighted, setIsLoadingWeighted] = useState(false);

    const location = useLocation();
    const { surveyId } = useParams(); // Extract survey ID from URL

    const navigate = useNavigate();

    useEffect(() => {
        let url = `${getEnvVariable('API_BASE_URL')}results/${surveyId}`
        authenticatedFetch(navigate, url)
            .then(res => {
                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }
                return res.json();
            })
            .then((votesResponse) => {
                if (Array.isArray(votesResponse) && votesResponse.length > 0) {
                    setVotes(votesResponse);

                    const filter = queryString.parse(location.search);

                    setUniqueCategories({
                        variant: getUnique('variant_id_winner', votesResponse).map((variantId) => {
                            // Find the vote object with the corresponding variant ID for the variant name
                            const vote = votesResponse.find((vote) => vote.variant_id_winner === variantId);
                            return {
                                variant: variantId,
                                variantName: vote.winner_variant_name,
                                isActive: filter.variant ? !filter.variant.split('-').includes(String(variantId)) : true
                            }
                        }),
                        user: getUnique('user_name', votesResponse).map(user => ({user: user,
                            isActive: filter.user ? !filter.user.split('-').includes(String(user)) : true})),
                        device: getUnique('device', votesResponse).map(device => ({device: device,
                            isActive: filter.device ? !filter.device.split('-').includes(device) : true})),
                        resolution: getUnique('screen_resolution', votesResponse).map(resolution => ({resolution: resolution,
                            isActive: filter.resolution ? !filter.resolution.split('-').includes(resolution) : true})),
                        startDate: filter.startDate ? filter.startDate : findMinDate(votesResponse.map(vote => vote.vote_timestamp)),
                        endDate: filter.endDate ? filter.endDate : findMaxDate(votesResponse.map(vote => vote.vote_timestamp))
                        });

                    // Automatically fetch weighted results after getting basic votes
                    fetchWeightedResults();
                } else {
                    setVotes("no results");
                }
            })
            .catch(error => {
                console.error('Error fetching results:', error);
                alert('Failed to fetch results. Please try again.');
            });
    }, [surveyId, location.search])

    // Function to fetch weighted analysis results
    const fetchWeightedResults = () => {
        setIsLoadingWeighted(true);
        let url = `${getEnvVariable('API_BASE_URL')}weighted_results/${surveyId}`
        authenticatedFetch(navigate, url)
            .then(res => {
                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }
                return res.json();
            })
            .then((data) => {
                setWeightedResults(data);
                setIsLoadingWeighted(false);
            })
            .catch(error => {
                console.error('Error fetching weighted results:', error);
                setIsLoadingWeighted(false);
            });
    };

    function findMinDate(dates) {
        // Convert each date string to a Date object and find the minimum date
        const minDate = new Date(Math.min(...dates.map(date => new Date(date))));

        // Format the minimum date back to 'yyyy-mm-dd'
        return minDate.toISOString().slice(0, 10);
    }

    function findMaxDate(dates) {
        const maxDate = new Date(Math.max(...dates.map(date => new Date(date))));

        // Increase the date by one day
        maxDate.setDate(maxDate.getDate() + 1);

        return maxDate.toISOString().slice(0, 10);
    }


    // Function to count votes for a specific variantId
    const countVotesForVariant = (votes, variantId) => {
        return votes.reduce((count, vote) => {
            if (vote.variant_id_winner === variantId) {
                if (
                    uniqueCategories.user.every(user => user.user !== vote.user_name || user.isActive) &&
                    uniqueCategories.device.every(device => device.device !== vote.device || device.isActive) &&
                    uniqueCategories.resolution.every(resolution => resolution.resolution !== vote.screen_resolution || resolution.isActive) &&
                    isDateInRange(vote.vote_timestamp.slice(0, 10))
                )
                    count++;
            }
            return count;
        }, 0); // Initialize count to 0
    };

    // Function to check if a date is within the selected range
    const isDateInRange = (timestamp) => {
        if (!uniqueCategories.startDate || !uniqueCategories.endDate) return true; // If no date range is selected, consider all dates
        return timestamp >= uniqueCategories.startDate && timestamp <= uniqueCategories.endDate;
    };

    // Function to generate direct link to results with applied filter
    const generateFilteredLink = (updatedFilter) => {
        const newURL = {}
        for (const key in updatedFilter) {
            newURL[key] = ''
            if ((key === 'startDate') || key === 'endDate') {
                newURL[key] += updatedFilter[key] !== '' ? updatedFilter[key] : ''
            }
            else {
                updatedFilter[key].forEach((category) => {
                    if (!category.isActive) {
                        newURL[key] += `${category[key]}-`
                    }
                })
            }
        }
        window.history.pushState(null, '', `/results/${surveyId}?${objToQueryString(newURL)}`);
    };


    // Function to handle changes in filter options
    const handleFilterChange = (category, value) => {
        // Toggle value in the array for the selected category
        const updatedFilter = {
            ...uniqueCategories,
            [category]: uniqueCategories[category].map(categoryItem => {
                if (categoryItem[category] === value) {
                    return {
                        ...categoryItem,
                        isActive: !categoryItem.isActive // Toggle the isActive property
                    };
                } else {
                    return categoryItem;
                }
            })
        };

        setUniqueCategories(updatedFilter);
        generateFilteredLink(updatedFilter);
    };

    // Function to toggle all checkboxes for a given category
    const toggleAllCheckboxes = (category) => {
        // Toggle all checkboxes based on the current state
        const allChecked = uniqueCategories[category].every(item => item.isActive);
        const updatedFilter = {...uniqueCategories, [category]:
            uniqueCategories[category].map((category)=> {
                if (!allChecked) {
                    // Not all checkboxes are currently checked, check all
                    return {...category, isActive: true}
                } else {
                    // All checkboxes are currently checked, uncheck all
                    return {...category, isActive: false}
                }
            })
        }
        setUniqueCategories(updatedFilter);
        generateFilteredLink(updatedFilter)
    };

    const handleFilterDate = (type, value) => {
        const updatedFilter = {...uniqueCategories, [type]: value};
        setUniqueCategories(updatedFilter);
        generateFilteredLink(updatedFilter)}

    // Function to export all votes data to CSV
    const exportToCSV = () => {
        // Add BOM for UTF-8
        let csvData = '\uFEFF';

        // Construct CSV header
        csvData += "Vote Date,Vote ID,Winner Variant ID,Loser Variant ID,Winner Variant Name,Loser Variant Name,User ID,User Name,Media,Device,Screen Resolution\n";

        // Append each vote to CSV data
        votes.forEach(vote => {
            csvData += `${new Date(vote.vote_timestamp).toLocaleString().replace(',','')},${vote.vote_id},${vote.variant_id_winner},${vote.variant_id_loser},"${vote.winner_variant_name}","${vote.loser_variant_name}",${vote.user_id},"${vote.user_name}","${vote.media}",${vote.device},${vote.screen_resolution}\n`;
        });

        // Create Blob object
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

        // Create URL for Blob object
        const url = URL.createObjectURL(blob);

        // Create link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `votes_data_for_${surveyId}_survey.csv`);

        // Simulate click on the link to initiate download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    // Render the matrix table
    const renderWeightedMatrix = () => {
        if (!weightedResults || !weightedResults.win_percentage_matrix) {
            return <p>No weighted data available</p>;
        }

        const variants = Object.keys(weightedResults.win_percentage_matrix);

        return (
            <div className="table-responsive">
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Win % Against →</th>
                            {variants.map(variant => (
                                <th key={variant}>{variant}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {variants.map(rowVariant => (
                            <tr key={rowVariant}>
                                <td className="fw-bold">{rowVariant}</td>
                                {variants.map(colVariant => {
                                    const percentage = rowVariant === colVariant
                                        ? "-"
                                        : weightedResults.win_percentage_matrix[rowVariant][colVariant].toFixed(1) + "%";

                                    return <td key={colVariant}>{percentage}</td>;
                                })}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    };

    // Render winning averages
    const renderWinningAverages = () => {
        if (!weightedResults || !weightedResults.winning_averages) {
            return <p>No weighted data available</p>;
        }

        const sortedVariants = Object.entries(weightedResults.winning_averages)
            .sort((a, b) => b[1] - a[1]);

        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Variant</th>
                        <th>Weighted Win Rate (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedVariants.map(([variant, percentage]) => (
                        <tr key={variant}>
                            <td>{variant}</td>
                            <td>{percentage.toFixed(1)}%</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };

    return (
        <Container>  {votes === "no results" ? <p>no results found</p> :
            (uniqueCategories.variant === undefined ? <Loading /> :
                <Container>
                    <Row className="mb-3">
                        <Col>
                            <h2>Results</h2>
                        </Col>
                        <Col className="text-end">
                            <Button variant="primary" onClick={exportToCSV}>
                                Export to CSV
                            </Button>
                        </Col>
                        </Row>
                        {/* Filtering options */}
                        <Row className="mb-3">
                        <Col md={4} className='bg-secondary-subtle rounded pt-2'>
                            {/* <h3>Filtering Options</h3> */}
                            {/* Variant filter */}
                            <Form.Group className="mb-3">
                                <Form.Label className='fw-bold'>Filter by Variants:</Form.Label>
                                <Button
                                    variant={uniqueCategories.variant.every(item => item.isActive) ? "outline-primary" : "primary"}
                                    size="sm"
                                    className="mb-2 d-block"
                                    onClick={() => toggleAllCheckboxes('variant')}
                                >
                                    {uniqueCategories.variant.every(item => item.isActive) ? "Deselect All" : "Select All"}
                                </Button>
                                {uniqueCategories.variant.map((variant) => (
                                    <Form.Check
                                        key={variant.variant}
                                        type="checkbox"
                                        id={`variant-${variant.variant}`}
                                        label={variant.variantName}
                                        checked={variant.isActive}
                                        onChange={() => handleFilterChange('variant', variant.variant)}
                                    />
                                ))}
                            </Form.Group>
                            {/* User filter */}
                            <Form.Group className="mb-3">
                                <Form.Label className='fw-bold'>Filter by Users:</Form.Label>
                                <Button
                                    variant={uniqueCategories.user.every(item => item.isActive) ? "outline-primary" : "primary"}
                                    size="sm"
                                    className="mb-2 d-block"
                                    onClick={() => toggleAllCheckboxes('user')}
                                >
                                    {uniqueCategories.user.every(item => item.isActive) ? "Deselect All" : "Select All"}
                                </Button>
                                {uniqueCategories.user.map(user => (
                                    <Form.Check
                                        key={user.user}
                                        type="checkbox"
                                        id={`user-${user.user}`}
                                        label={user.user}
                                        checked={user.isActive}
                                        onChange={() => handleFilterChange('user', user.user)}
                                    />
                                ))}
                            </Form.Group>
                            {/* Date filter */}
                            <Form.Group className="mb-3">
                                <Form.Label className='fw-bold'>Filter by Dates:</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Start Date"
                                    min={findMinDate(votes.map(vote => vote.vote_timestamp))}
                                    value={uniqueCategories.startDate}
                                    onChange={(e) => handleFilterDate('startDate', e.target.value)}
                                />
                                <Form.Control
                                    type="date"
                                    placeholder="End Date"
                                    max={findMaxDate(votes.map(vote => vote.vote_timestamp))}
                                    value={uniqueCategories.endDate}
                                    onChange={(e) => handleFilterDate('endDate', e.target.value)}
                                />
                            </Form.Group>
                            {/* devices filter */}
                            <Form.Group className="mb-3">
                                <Form.Label className='fw-bold'>Filter by Devices:</Form.Label>
                                <Button
                                    variant={uniqueCategories.device.every(item => item.isActive) ? "outline-primary" : "primary"}
                                    size="sm"
                                    className="mb-2 d-block"
                                    onClick={() => toggleAllCheckboxes('device')}
                                >
                                    {uniqueCategories.device.every(item => item.isActive) ? "Deselect All" : "Select All"}
                                </Button>
                                {uniqueCategories.device.map(device => (
                                    <Form.Check
                                        key={device.device}
                                        type="checkbox"
                                        id={`device-${device.device}`}
                                        label={device.device}
                                        checked={device.isActive}
                                        onChange={() => handleFilterChange('device', device.device)}
                                    />
                                ))}
                            </Form.Group>
                            {/* screen resolutions filter */}
                            <Form.Group className="mb-3">
                                <Form.Label className='fw-bold'>Filter by Screen Resolutions:</Form.Label>
                                <Button
                                    variant={uniqueCategories.resolution.every(item => item.isActive) ? "outline-primary" : "primary"}
                                    size="sm"
                                    className="mb-2 d-block"
                                    onClick={() => toggleAllCheckboxes('resolution')}
                                >
                                    {uniqueCategories.resolution.every(item => item.isActive) ? "Deselect All" : "Select All"}
                                </Button>
                                {uniqueCategories.resolution.map(resolution => (
                                    <Form.Check
                                        key={resolution.resolution}
                                        type="checkbox"
                                        id={`resolution-${resolution.resolution}`}
                                        label={resolution.resolution}
                                        checked={resolution.isActive}
                                        onChange={() => handleFilterChange('resolution', resolution.resolution)}
                                    />
                                ))}
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Tab.Container defaultActiveKey="basic">
                                <Nav variant="tabs" className="mb-3">
                                    <Nav.Item>
                                        <Nav.Link eventKey="basic">Basic Vote Counts</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="weighted">
                                            Weighted Analysis
                                            {isLoadingWeighted && <span className="ms-2"><Loading size="sm" /></span>}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="basic">
                                        {/* Table to display vote results */}
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Variant Name</th>
                                                    <th>Number of votes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {uniqueCategories.variant.map((variant) => {
                                                    if (!variant.isActive)
                                                        return null;
                                                    return (
                                                        <tr key={variant.variant}>
                                                            <td>{variant.variantName}</td>
                                                            <td>{countVotesForVariant(votes, variant.variant)}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="weighted">
                                        {isLoadingWeighted ? (
                                            <div className="text-center p-4">
                                                <Loading />
                                                <p>Calculating weighted results...</p>
                                            </div>
                                        ) : (
                                            <>
                                                <h4>Weighted Win Rate by Variant</h4>
                                                <p className="text-muted small">
                                                    Each user's votes are weighted equally regardless of how many votes they cast.
                                                </p>
                                                {renderWinningAverages()}

                                                <h4 className="mt-4">Weighted Win Percentage Matrix</h4>
                                                <p className="text-muted small">
                                                    Shows how often each variant wins against each other variant (weighted by user).
                                                </p>
                                                {renderWeightedMatrix()}
                                            </>
                                        )}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Container>
            )}
        </Container>
    );
};

export default Results;
